.cardContent {
    margin-bottom: 5px;
}

.card--content-hidden {
    display: none;
}

.button--contentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
}

.button--contentContainer-center {
    padding: 0 10px;
}

.cardBottom {
    display: flex;
    flex-direction: row;
}

.cardBottom--button {
    min-width: 50px !important;
    margin: 0 5px !important;
}

.cardBottom--button-identifier:hover {
    cursor: default;
}

.cardBottom--button-fold {
    flex-grow: 1;
    // margin: 0 10px !important;
}

.select__item::first-letter {
    text-transform: uppercase !important;
}
