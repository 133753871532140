::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background: rgb(230, 230, 230);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(185, 185, 185);
}

html,
body,
#root,
.App,
.App > div {
  height: 100%;
}

.button:focus {
  outline: none;
}

p {
  margin: 0;
}

hr {
  margin-left: 0;
  margin-right: 0;
}

h1 {
  font-size: 1rem;
}

select {
  max-width: 100%;
}

.App > div > div {
  min-height: 100%;
}

.Card {
  margin: 20px;
  padding: 20px;
  position: relative;
}

.Paper {
  margin: 10px 0;
  padding: 10px;
}

.cardLabel {
  position: absolute;
  top: 4px;
  left: 6px;
  color: #aaa;
  font-size: 15px;
}

.DocumentOutput {
  display: flex;
  flex-direction: column;
  max-height: 100vh;

  & .Card {
    display: flex;
    flex-direction: column;
  }
}

.container-scrollable {
  flex-grow: 1;
  overflow-y: scroll !important;
}

.container--generalInfo {
  flex-shrink: 0;
}
