.TopBar {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  color: white;
}

.TopBar__options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #242731;
  z-index: 1;
}

.TopBar__header {
  margin: 10px auto;
  flex-grow: 1;
  text-align: center;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.TopBar__button-group {
  margin: 10px;
}

.TopBar__button {
  min-height: 30px;
}

.TopBar__imgContainer {
  margin: auto 0 0 0;
  width: 70px;
  max-height: 45px;
}

.TopBar__img {
  max-height: inherit;
  max-width: inherit;
}

.TopBar__dropdown {
  transition: top 1s;
  position: absolute;
  top: -350%;
  background-color: #383d4d;
  width: 100%;
  padding: 10px;
}

.TopBar__dropdown--visible {
  top: 100%;
}
